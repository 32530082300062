<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>资产管理</a-breadcrumb-item>
            <a-breadcrumb-item>多经点位管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <a-input-group style="width: 300px" compact>
            <a-select
              style="width: 90px"
              v-model="value"
              default-value="cs.name"
            >
              <a-select-option value="cd.name"> 铺位号 </a-select-option>
              <a-select-option value="cd.buildingName"> 楼栋 </a-select-option>
              <a-select-option value="cd.state"> 状态 </a-select-option>
            </a-select>
            <a-select
              v-if="value == 'state'"
              @change="onSearch"
              style="width: 210px"
            >
              <a-select-option value="0"> 启用 </a-select-option>
              <a-select-option value="1"> 禁用 </a-select-option>
            </a-select>
            <a-input-search
              v-else
              v-model="key"
              placeholder="请输入搜索内容"
              style="width: 210px"
              @search="onSearch"
            />
          </a-input-group>
          <a-button @click="outexcel">
            <a class="a" :href="apiexp">下载模板</a>
          </a-button>
          <a-upload
            name="file"
            :multiple="true"
            :action="apiein"
            :headers="headers"
            @change="handleChange"
          >
            <a-button> 导入表格 </a-button>
          </a-upload>
          <a-button type="primary" @click.prevent="showDrawer">
            新增多经
          </a-button>
          <a-button type="danger" @click.prevent="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <div v-if="!visible">
              <a-table
                :row-selection="rowSelection"
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="djlist"
                :rowClassName="rowClassName"
              >
                <span slot="status" slot-scope="text, record">
                  <a-switch
                    :checked="text == 0 ? true : false"
                    default-checked
                    @change="onchangestate(record)"
                  />
                </span>
                <template slot="action" slot-scope="text, record">
                  <a @click="onEdit(record)">编辑</a>
                </template>
                <!-- <a slot="gender" slot-scope="item">{{
                  item.gender == 1 ? "男" : "女"
                }}</a>
                <a-button
                  slot="state"
                  slot-scope="item"
                  :type="item.State == -1 ? 'danger' : 'primary'"
                ></a-button> -->
              </a-table>
              <a-pagination
                v-model="params.current"
                :page-size="params.pageSize"
                show-quick-jumper
                :default-current="2"
                :total="total"
                @change="onpagesize"
              />
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 编辑页 -->
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-layout-content
          :style="{
            background: '#fff',
            margin: 0,
          }"
        >
          <div class="content-title">
            <span>基础信息</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <a-form-model-item prop="projectId" label="项目名称">
              <a-select
                style="width: 100%"
                @change="properyChange"
                placeholder="请选择项目"
                disabled="disabled"
                v-model="form.projectId"
              >
                <a-select-option
                  v-for="item in projectlist"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.shortName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="buildingId" label="楼栋">
              <a-select
                style="width: 100%"
                @change="buildChange"
                placeholder="请选择楼栋"
                v-model="form.buildingId"
              >
                <a-select-option v-for="item in buildlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="floorId" label="楼层">
              <a-select
                v-model="form.floorId"
                style="width: 100%"
                @change="floorChange"
                placeholder="请选择楼层"
              >
                <a-select-option v-for="item in floorlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" prop="name" label="铺位号">
              <a-input
                placeholder="请输入铺位号"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.name"
              />
            </a-form-model-item>
            <a-form-model-item
              style="width: 20%"
              ref="totalArea"
              prop="totalArea"
              label="建筑面积"
            >
              <a-input
                placeholder="请输入建筑面积"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.totalArea"
              />
            </a-form-model-item>
            <a-form-model-item
              style="width: 20%"
              ref="useArea"
              prop="useArea"
              label="使用面积"
            >
              <a-input
                placeholder="请输入使用面积"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.useArea"
              />
            </a-form-model-item>
            <a-form-model-item
              style="width: 20%"
              ref="rentArea"
              prop="rentArea"
              label="计租面积"
            >
              <a-input
                placeholder="请输入计租面积"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                v-model="form.rentArea"
              />
            </a-form-model-item>
            <a-form-model-item style="width: 20%" prop="state" label="招商状态">
              <a-select
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                v-model="form.state"
              >
                <a-select-option value="0">启用</a-select-option>
                <a-select-option value="1">禁用</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              style="width: 20%"
              prop="propertyTypeId"
              label="产权属性"
            >
              <a-select
                style="width: 100%"
                :filter-option="filterOption"
                @change="typeChange"
                placeholder="请选择产权"
                v-model="form.propertyTypeId"
              >
                <a-select-option v-for="item in typelist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-layout-content>

        <!-- 价格 -->
        <a-layout-content style="background: #fff; margin: 24px 0">
          <div class="content-title">
            <span>价格信息</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <!-- <a-form-model-item
              ref="monthLowestFee"
              prop="monthLowestFee"
              class="price-formitem"
              label="月标准价格"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
                placeholder="请输入标准价格"
                v-model="form.monthLowestFee"
              />
              <div class="price">元/m²·月</div>
            </a-form-model-item>
            <a-form-model-item
              ref="monthStandardFee"
              prop="monthStandardFee"
              class="price-formitem"
              label="月最低价格"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
                placeholder="请输入最低价格"
                v-model="form.monthStandardFee"
              />
              <div class="price">元/m²·月</div>
            </a-form-model-item> -->
            <a-form-model-item style="width: 20%" label="单位">
              <a-input disabled="disabled" value="元/m²·天" />
            </a-form-model-item>
            <a-form-model-item
              ref="dayLowestFee"
              prop="dayLowestFee"
              class="price-formitem"
              label="日标准价格"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入日标准价格"
                v-model="form.dayLowestFee"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="dayStandardFee"
              prop="dayStandardFee"
              class="price-formitem"
              label="日最低价格"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入日最低价格"
                v-model="form.dayStandardFee"
              />
            </a-form-model-item>
          </div>
        </a-layout-content>
      </a-form-model>
      <!-- 图片 -->
      <a-layout-content
        style="background: #fff; margin-bottom: 24px; height: 217px"
      >
        <div class="content-title">
          <span>图片</span>
        </div>
        <div class="content-body">
          <div class="clearfix">
            <a-upload
              action=""
              list-type="picture-card"
              :file-list="form.images"
              @preview="handlePreview"
              :customRequest="handleChange"
              @change="handleChanges"
            >
              <div v-if="form.images.length < 5">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </div>
      </a-layout-content>
      <!-- 扩展信息 -->
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>扩展信息</span>
          <a-icon
            v-if="extension == 0"
            @click="
              () => {
                this.extension = 1
              }
            "
            style="cursor: pointer"
            type="down"
          />
          <a-icon
            v-else
            @click="
              () => {
                this.extension = 0
              }
            "
            style="cursor: pointer"
            type="up"
          />
        </div>
        <div v-show="extension == 1">a</div>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '铺位号',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '楼栋',
    dataIndex: 'buildingName',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '建筑面积',
    dataIndex: 'totalArea',
  },
  {
    title: '使用面积',
    dataIndex: 'useArea',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const OSS = require('ali-oss')
let client
const api = process.env.VUE_APP_API_BASE_URL
import { nanoid } from 'nanoid'
import { imageMixin } from '../../../mixins'
import http from '../../../http'
export default {
  mixins: [imageMixin],
  data() {
    return {
      apiexp: api + 'property/store/excel/export',
      apiinp: api + 'property/store/excel/import',
      previewVisible: false,
      previewImage: '',
      extension: 0,
      headers: {
        authorization: 'authorization-text',
      },
      logvisible: false,
      file: null,
      filetxt: {},
      // 行内
      columns,
      key: '',
      visible: false,
      labelCol: { span: 12 },
      wrapperCol: { span: 24 },
      // 要传参数
      projectlist: [],
      buildlist: [],
      floorlist: [],
      djlist: [],
      selectedRowKey: [],
      value: 'cd.name',
      params: {
        current: 1,
        pageSize: 10,
      },
      total: 6,
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      Id: '',
      form: {
        id: '',
        name: '',
        totalArea: '',
        useArea: '',
        rentArea: '',
        dayLowestFee: '',
        dayStandardFee: '',
        monthLowestFee: '',
        monthStandardFee: '',
        projectId: JSON.parse(localStorage.getItem('store-userlist'))
          .projectInfo.projectId,
        floorId: '',
        images: [],
        buildingId: '',
        propertyTypeId: '1426051906128113665',
        state: '',
      },
      rules: {
        projectId: [
          {
            required: true,
            message: '请选择项目',
            trigger: 'change',
          },
        ],
        buildingId: [
          {
            required: true,
            message: '请选择楼栋',
            trigger: 'change',
          },
        ],
        floorId: [
          {
            required: true,
            message: '请选择楼层',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: '铺位号不能为空',
            trigger: 'blur',
          },
        ],
        propertyTypeId: [
          {
            required: true,
            message: '请选择产权属性',
            trigger: 'change',
          },
        ],
        totalArea: [
          {
            required: true,
            message: '建造面积不能为空',
            trigger: 'blur',
          },
        ],
        useArea: [
          {
            required: true,
            message: '使用面积不能为空',
            trigger: 'blur',
          },
        ],
        rentArea: [
          {
            required: true,
            message: '计租面积不能为空',
            trigger: 'blur',
          },
        ],
        state: [
          {
            required: true,
            message: '招商状态不能为空',
            trigger: 'change',
          },
        ],
        monthLowestFee: [
          {
            required: true,
            message: '月标准价格不能为空',
            trigger: 'blur',
          },
        ],
        monthStandardFee: [
          {
            required: true,
            message: '月最低价格不能为空',
            trigger: 'blur',
          },
        ],
        dayLowestFee: [
          {
            required: true,
            message: '日标准价格不能为空',
            trigger: 'blur',
          },
        ],
        dayStandardFee: [
          {
            required: true,
            message: '日最低价格不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    onSearch(value) {
      this.params = {
        current: 1,
        pageSize: 10,
      }
      this.params[this.value] = {
        value: value,
        op: '%like%',
      }
      if (this.value == 'cd.state') {
        this.params[this.value].op = '='
      }
      this.key = ''
      this.getdj()
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleChanges({ fileList }) {
      if (this.form.images.length > fileList.length) {
        this.form.images = fileList
      }
    },
    handleChange(file) {
      var data = file.file
      this.putObject(client, nanoid(), file.file.name.split('.')[1], data)
    },
    async putObject(client, id, name, data) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.form.images.push({
            bucket: 'sccncloud2',
            name: item.name,
            uid: id,
            status: 'done',
            url: url,
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
      this.buildingid(
        JSON.parse(localStorage.getItem('store-userlist')).projectInfo.projectId
      )
    },
    onClose() {
      this.form = {
        id: '',
        name: '',
        totalArea: '',
        useArea: '',
        images: [],
        rentArea: '',
        dayLowestFee: '',
        dayStandardFee: '',
        monthLowestFee: '',
        projectId: JSON.parse(localStorage.getItem('store-userlist'))
          .projectInfo.projectId,
        monthStandardFee: '',
        floorId: '',
        propertyTypeId: '1426051906128113665',
        state: '',
      }
      this.visible = false
      this.Id = ''
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.images = JSON.stringify(this.form.images)
          //   console.log("submit!", this.form);
          if (this.Id == '') {
            this.Adddj(this.form)
          } else if (this.Id != '') {
            this.putdj(this.form)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    onpagesize(e) {
      this.params.current = e
      this.getdj()
    },
    // 请求
    async getdj() {
      try {
        const res = await http.getdj(this.params)
        const { success, data } = res.data
        if (success) {
          this.djlist = data.djs.records
          this.total = data.djs.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('ex:', ex)
      }
    },
    async getdjId(id) {
      try {
        const res = await http.getdjId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.form.name = data.dj.name
          this.form.totalArea = data.dj.totalArea
          this.form.useArea = data.dj.useArea
          this.form.rentArea = data.dj.rentArea
          this.form.dayLowestFee = data.dj.dayLowestFee
          this.form.dayStandardFee = data.dj.dayStandardFee
          this.form.monthLowestFee = data.dj.monthLowestFee
          this.form.monthStandardFee = data.dj.monthStandardFee
          this.form.floorId = data.dj.floorId
          this.form.projectId = data.dj.projectId
          this.form.buildingId = data.dj.buildingId
          setTimeout(() => {
            if (data.dj.images == null) {
              this.form.images = []
            } else {
              this.form.images = JSON.parse(data.dj.images)
            }
            var a = []
            this.form.images.forEach(item => {
              let url
              url = client.signatureUrl(item.name)
              a.push({
                bucket: 'sccncloud2',
                name: item.name,
                uid: nanoid(),
                status: 'done',
                url: url,
              })
            })
            this.form.images = a
          }, 100)
          this.buildingid(data.dj.projectId)
          this.floorid(data.dj.buildingId)
          this.form.propertyTypeId = data.dj.propertyTypeId
          this.form.state = data.dj.state
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 新增
    async Adddj(form) {
      try {
        const res = await http.Adddj(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success(msg)
          this.onClose()
        } else {
          this.$message.console.warn()
          msg
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    // 编辑
    onEdit(e) {
      //   console.log(e);
      this.Id = e.id
      this.form.id = e.id
      this.visible = true
      this.title = '编辑'
      this.getdjId(e.id)
    },
    // 编辑
    async putdj(form) {
      try {
        const res = await http.putdj(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
        this.onClose()
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    outexcel() {
      this.exportdj()
    },
    async exportdj() {
      try {
        const res = await http.exportdj()
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    // 删除
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该多经点位么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deletedj()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deletedj() {
      try {
        const res = await http.deletedj(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    //Change事件
    buildChange(value) {
      this.floorlist = []
      this.Storelist = []
      this.form.floorId = ''
      this.form.name = ''
      //   console.log(value);
      this.floorid(value)
    },
    floorChange() {
      this.Storelist = []
      this.form.name = ''
      //   console.log(value);
    },
    //下拉框
    async project() {
      try {
        const res = await http.project()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.projectlist = data.projects
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildlist = data.buildings
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async Statedj(id, state) {
      try {
        const res = await http.Statedj(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async type() {
      try {
        const res = await http.type()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.typelist = data.propertyTypes
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    handleClickRow(value) {
      return {
        on: {
          dblclick: () => {
            this.display = 'none'
            this.dis = 'block'
            // console.log(value);
            this.thing = value
          },
        },
      }
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.$message.success('操作成功')
      this.Statedj(e.id, this.form.state)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    timer() {
      return setTimeout(() => {
        this.getdj()
      }, 500)
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
  },
  mounted() {
    this.getdj()
    this.project()
    this.type()
    this.getsts()
    const users = JSON.parse(localStorage.getItem('store-userlist'))
    if (users == null) {
      this.logvisible = true
    }
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  height: 100%;
  min-height: 730px;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.price {
  position: absolute;
  right: 1px;
  bottom: -6px;
  width: 70px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
}
.ant-btn {
  margin: 0px 8px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.content-body {
  padding: 24px;
}
.content-body .price-formitem {
  position: relative;
  width: 40%;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 90px;
  margin: 0;
  width: 25%;
  padding-right: 20px;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.a {
  color: #5f5f5f;
}
.a:hover {
  color: #1890ff;
}
</style>
